//=========Menu Burger================

document.addEventListener('turbo:load', function () {
  var openMenu = document.querySelector('.burger-btn');
  var menu = document.querySelector('.adaptive-menu');
  var body = document.querySelector('body');
  var menuLinks = menu.querySelectorAll('a');

  function toggleMenu() {
    menu.classList.toggle('active');
    openMenu.classList.toggle('active');
    if (menu.classList.contains('active')) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = '';
    }
  }

  openMenu.addEventListener('click', function (event) {
    toggleMenu();
    event.stopPropagation();
  });

  menu.addEventListener('click', function (event) {
    event.stopPropagation();
  });

  document.addEventListener('click', function (event) {
    var isClickInsideMenu = menu.contains(event.target);

    if (!isClickInsideMenu && menu.classList.contains('active')) {
      toggleMenu();
    }
  });

  menuLinks.forEach(function (link) {
    link.addEventListener('click', function () {
      toggleMenu();
    });
  });
});



//=======Adaptive parent & child===================

document.addEventListener('DOMContentLoaded', function () {
  const adaptiveParent = document.querySelector('.parent-link');

  adaptiveParent.addEventListener('click', function (event) {
    event.stopPropagation();
    adaptiveParent.classList.toggle('active');
  });

  document.addEventListener('click', function (event) {
    if (!adaptiveParent.contains(event.target)) {
      adaptiveParent.classList.remove('active');
    }
  });
});






