
//========MAIN POPUP=================

var startTime = parseInt(sessionStorage.getItem('startTime')) || new Date().getTime();
var popupShown = sessionStorage.getItem('popupShown') === 'true';

function updateTimer() {
  var currentTime = new Date().getTime();
  var elapsedTime = currentTime - startTime;

  sessionStorage.setItem('startTime', startTime.toString());

  var mainPopup = document.getElementById('main-popup');

  var elapsedSeconds = Math.floor(elapsedTime / 1000);

  if (!popupShown && elapsedSeconds >= 30) {
    mainPopup.style.visibility = "visible";
    sessionStorage.setItem('popupShown', 'true');
    popupShown = true;
  }
}

setInterval(updateTimer, 1000);


document.addEventListener('turbo:load', function () {
  let popupOverlay = document.querySelector('#main-popup');
  let closeBtn = document.querySelector('#close-main-popup');

  if (popupOverlay) {
    function openPopup() {
      popupOverlay.style.visibility = 'visible';
      popupOverlay.style.overflow = 'visible';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
      popupOverlay.style.overflow = 'hidden'; 
    }

    closeBtn.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    document.querySelectorAll('.home-request-btn').forEach(function (button) {
      button.addEventListener('click', openPopup);
    });
  }
});



//======Offer Request Popup

document.addEventListener('turbo:load', function () {
  let popupOverlay = document.querySelector('#popup-offer-request');
  let closeBtn = document.querySelector('#close-offer-request');
  let openPopupBtns = document.querySelectorAll('.offer-request-btn');
  let timerBox = document.querySelector('.pop-timer-box');

  if (popupOverlay) {
    function openPopup(showTimer) {
      if (timerBox) {
        if (showTimer) {
          timerBox.style.display = 'block';
        } else {
          timerBox.style.display = 'none';
        }
      }
      popupOverlay.style.visibility = 'visible';
    }

    function closePopup() {
      popupOverlay.style.visibility = 'hidden';
    }

    closeBtn?.addEventListener('click', closePopup);

    popupOverlay.addEventListener('click', function (event) {
      if (event.target === popupOverlay) {
        closePopup();
      }
    });

    openPopupBtns.forEach(function (btn) {
      btn.addEventListener('click', function () {
        if (btn.id === 'with-timer') {
          openPopup(true);
        } else {
          openPopup(false);
        }
      });
    });
  }
});

